<template>
    <figure class="flex flex-wrap justify-center object-cover object-center gap-4 prose-img:cursor-pointer">
        <a href="https://www.gerenciemei.com.br/" target="_blank"
            class="duration-300 transform scale-95 opacity-75 transition-ease-in-out hover:opacity-100 hover:scale-100">
            <img src="@/assets/images/projects/case1.webp" width="320" height="180" class="shadow-md rounded-xl"
                alt="imagem do projeto 1"
                title="Gerencie MEI site para gestão de empresas de pequenos empreendedores" />
        </a>
        <a href="https://elivandro.github.io/learn-proj/CMLife/" target="_blank"
            class="duration-300 transform scale-95 opacity-75 transition-ease-in-out hover:opacity-100 hover:scale-100">
            <img src="@/assets/images/projects/case2.webp" width="320" height="180" class="shadow-md rounded-xl"
                alt="imagem do projeto 2" title="Clínica médica Life, exemplo de um site de clínica médica" />
        </a>
        <a href="https://elivandro.github.io/learn-proj/PousadaSecreta/" target="_blank"
            class="duration-300 transform scale-95 opacity-75 transition-ease-in-out hover:opacity-100 hover:scale-100">
            <img src="@/assets/images/projects/case3.webp" width="320" height="180" class="shadow-md rounded-xl"
                alt="imagem do projeto 3" title="Pousada Secreta, exemplo do site de uma pousada com resort" />
        </a>
        <a href="https://elivandro.github.io/learn-proj/projetoEstagio/" target="_blank"
            class="duration-300 transform scale-95 opacity-75 transition-ease-in-out hover:opacity-100 hover:scale-100">
            <img src="@/assets/images/projects/case4.webp" width="320" height="180" class="shadow-md rounded-xl"
                alt="imagem do projeto 4" title="Busca de pacotes de viagens e hospedagem, exemplo de site" />
        </a>
        <a href="https://elivandro.github.io/learn-proj/AgenciaViagens/" target="_blank"
            class="duration-300 transform scale-95 opacity-75 transition-ease-in-out hover:opacity-100 hover:scale-100">
            <img src="@/assets/images/projects/case5.webp" width="320" height="180" class="shadow-md rounded-xl"
                alt="imagem do projeto 5" title="Busca de pacotes de viagens e hospedagem, exemplo de site" />
        </a>
        <!-- <a href="https://viacnt.com.br/" target="_blank" class="duration-300 transform scale-95 opacity-75 transition-ease-in-out hover:opacity-100 hover:scale-100">
            <img src="@/assets/images/projects/case6.webp" width="320" height="180" class="shadow-md rounded-xl" alt="imagem do projeto 5" title="Busca de pacotes de viagens e hospedagem, exemplo de site" />
        </a> -->
    </figure>
</template>

<script>
export default {
    name: 'MyCases',
}
</script>